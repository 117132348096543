// React
import { Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./AuthContext";

// Components
import ProtectedRoute from "./ProtectedRoute";
import Signin from "./Signin";
import BlockchainDashboard from "./BlockchainDashboard";

// A ---------------------------------------------------------------------- M

const App = () => {
  return (
    <AuthContextProvider>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <BlockchainDashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthContextProvider>
  );
};

export default App;
