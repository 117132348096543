// React
import React from "react";
import { Navigate } from "react-router-dom";

// Contexts
import { UserAuth } from "./AuthContext";

// A --------------------------------------------------------------------- M

const ProtectedRoute = ({ children }) => {
  const { user } = UserAuth();

  if (user) {
    return children;
  }

  return <Navigate to="/signin" />;
};

export default ProtectedRoute;
