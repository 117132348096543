// React
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// react-pwa-install
import ReactPWAInstallProvider from "react-pwa-install";

// App
import App from "./App";

// Service Worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// A ---------------------------------------------------------------------- M

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReactPWAInstallProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReactPWAInstallProvider>
);

// Register service worker (only in production)
if (process.env.NODE_ENV === "production") {
  serviceWorkerRegistration.register();
}
