// React
import React, { useState, useEffect, useCallback } from "react";
import { UserAuth } from "./AuthContext";

// Firebase
import { db } from "./firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getCountFromServer,
  Timestamp,
} from "firebase/firestore";

// chart.js
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";

// Material UI Components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Components
import ApiKeysUsageTable from "./ApiKeysUsageTable";
import KeysUsageTable from "./KeysUsageTable";
import WidgetCard from "./WidgetCard";

// A ---------------------------------------------------------------------- M

ChartJS.register(
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const BlockchainDashboard = () => {
  const { user, logout } = UserAuth();

  const [records, setRecords] = useState([]);
  const [apiKeysStats, setApiKeysStats] = useState([]);
  const [keysStats, setKeysStats] = useState([]);
  const [totalByApiKeys, setTotalByApiKeys] = useState(0);
  const [totalByKeys, setTotalByKeys] = useState(0);
  const [totalCertifications, setTotalCertifications] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const apiKeysRef = collection(db, "apikeys_and_onchain_count");
    const keysRef = collection(db, "keys_and_onchain_count");
    const qApiKeys = query(
      apiKeysRef,
      where("owner_ids", "array-contains", user.uid)
    );
    const qKeys = query(
      keysRef,
      where("owner_ids", "array-contains", user.uid)
    );

    const [apikeysSnapshot, keysSnapshot] = await Promise.all([
      getDocs(qApiKeys),
      getDocs(qKeys),
    ]);

    // Fetch daily records (batch all queries for 5 days)
    const recordPromises = Array.from({ length: 5 }).map(async (_, index) => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - index);
      currentDate.setHours(0, 0, 0, 0);
      const startOfDay = currentDate.getTime();
      const startOfDayUnixSeconds = startOfDay / 1000;
      const startOfDayTimestamp = new Timestamp(startOfDayUnixSeconds, 0);
      currentDate.setHours(23, 59, 59, 999);
      const endOfDay = currentDate.getTime();
      const endOfDayUnixSeconds = endOfDay / 1000;
      const endOfDayTimestamp = new Timestamp(endOfDayUnixSeconds, 0);

      const totalByApiKeys = (
        await Promise.all(
          apikeysSnapshot.docs.map(async (doc) => {
            const billingRef = collection(doc.ref, "billing");
            const q = query(
              billingRef,
              where("timestamp", ">=", startOfDayTimestamp),
              where("timestamp", "<=", endOfDayTimestamp)
            );
            const snapshot = await getCountFromServer(q);
            return snapshot.data().count;
          })
        )
      ).reduce((acc, curr) => acc + curr, 0);

      const totalByKeys = (
        await Promise.all(
          keysSnapshot.docs.map(async (doc) => {
            const billingRef = collection(doc.ref, "billing");
            const q = query(
              billingRef,
              where("timestamp", ">=", startOfDayTimestamp),
              where("timestamp", "<=", endOfDayTimestamp)
            );
            const snapshot = await getCountFromServer(q);
            return snapshot.data().count;
          })
        )
      ).reduce((acc, curr) => acc + curr, 0);

      const total = totalByApiKeys + totalByKeys;

      const formattedDate = currentDate.toISOString().split("T")[0];

      return {
        x: formattedDate,
        total: total,
        totalByApiKeys: totalByApiKeys,
        totalByKeys: totalByKeys,
      };
    });

    const records = await Promise.all(recordPromises);
    const recordsFiltered = records.filter((obj) => obj !== undefined);
    console.log(recordsFiltered);
    setRecords(recordsFiltered.reverse());

    // Aggregate API keys usage data
    const apiKeysStatsPromises = apikeysSnapshot.docs.map(async (doc) => {
      const apiKeyID = doc.id;
      const billingRef = collection(apiKeysRef, `${apiKeyID}/billing`);
      const statsPerMonth = await fetchStatsPerMonth(billingRef);
      return {
        apiKeyID,
        ...doc.data(),
        stats: statsPerMonth,
      };
    });
    const apiKeys = await Promise.all(apiKeysStatsPromises);
    console.log(apiKeys);
    setApiKeysStats(apiKeys);

    const totalByApiKeys = apiKeys.reduce(
      (acc, rec) => acc + rec.stats[0]?.count,
      0
    );
    setTotalByApiKeys(totalByApiKeys);

    // Aggregate public keys usage data
    const keysStatsPromises = keysSnapshot.docs.map(async (doc) => {
      const keyID = doc.id;
      const billingRef = collection(keysRef, `${keyID}/billing`);
      const statsPerMonth = await fetchStatsPerMonth(billingRef);
      return {
        keyID,
        ...doc.data(),
        stats: statsPerMonth,
      };
    });
    const keys = await Promise.all(keysStatsPromises);
    console.log(keys);
    setKeysStats(keys);

    const totalByKeys = keys.reduce((acc, rec) => acc + rec.stats[0]?.count, 0);
    setTotalByKeys(totalByKeys);

    setTotalCertifications(totalByApiKeys + totalByKeys);
    setIsLoading(false);
  }, [user]);

  const fetchStatsPerMonth = async (billingRef) => {
    return Promise.all(
      Array.from({ length: 5 }).map(async (_, index) => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - index);
        currentDate.setDate(1);
        currentDate.setHours(0, 0, 0, 0);
        const startOfMonth = currentDate.getTime();
        const startOfMonthUnixSeconds = startOfMonth / 1000;
        const startOfMonthTimestamp = new Timestamp(startOfMonthUnixSeconds, 0);
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(0);
        currentDate.setHours(23, 59, 59, 999);
        const endOfMonth = currentDate.getTime();
        const endOfMonthUnixSeconds = endOfMonth / 1000;
        const endOfMonthTimestamp = new Timestamp(endOfMonthUnixSeconds, 0);

        const monthlyQuery = query(
          billingRef,
          where("timestamp", ">=", startOfMonthTimestamp),
          where("timestamp", "<=", endOfMonthTimestamp)
        );
        const snapshot = await getCountFromServer(monthlyQuery);
        return {
          month: currentDate.toISOString().split("T")[0].slice(0, 7),
          count: snapshot.data().count,
        };
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const data = {
    labels: records.map((record) => record.x),
    datasets: [
      {
        label: "Total N. Certifications",
        data: records.map((record) => record.total),
        borderColor: "#212121",
        tension: 0.1,
      },
      {
        label: "Tier-1 N. Certifications",
        data: records.map((record) => record.totalByApiKeys),
        borderColor: "#ef5350",
        tension: 0.1,
      },
      {
        label: "Tier-2 N. Certifications",
        data: records.map((record) => record.totalByKeys),
        borderColor: "#66bb6a",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        suggestedMin: 0,
      },
    },
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <Box sx={{ flexGrow: 1 }} />
            <Button color="inherit" onClick={logout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      {isLoading ? (
        <Grid item container justifyContent="center" mt="30%">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          sx={{ p: { xs: "5%", md: "2%" }, mt: "2%" }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography variant="h5" color="#212121">
              Consumptions Dashboard
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: "1%" }}>
              <Typography variant="h5" color="gray" gutterBottom>
                Certifications Overview
              </Typography>
              <Grid container alignItems="center">
                <Grid item container xs={2} justifyContent="center" spacing={1}>
                  <Grid item xs={10}>
                    <WidgetCard
                      totalCertifications={totalCertifications}
                      title={
                        <>
                          Total N. Certifications <br />
                          (last 30 days)
                        </>
                      }
                      lineColor="#212121"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <WidgetCard
                      totalCertifications={totalByApiKeys}
                      title={
                        <>
                          Tier-1 N. Certifications
                          <br />
                          (last 30 days)
                        </>
                      }
                      lineColor="#ef5350"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <WidgetCard
                      totalCertifications={totalByKeys}
                      title={
                        <>
                          Tier-2 N. Certifications
                          <br />
                          (last 30 days)
                        </>
                      }
                      lineColor="#66bb6a"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  <Line options={options} data={data} height="150vw" />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <ApiKeysUsageTable apiKeysStats={apiKeysStats} />
          </Grid>
          <Grid item xs={12}>
            <KeysUsageTable keysStats={keysStats} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BlockchainDashboard;
