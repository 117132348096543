// React
import React from "react";

// Material UI Components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";

// A ---------------------------------------------------------------------- M

const StyledCard = styled(Card)({
  margin: "auto",
  border: "none",
  boxShadow: "none",
});

const StyledCardContent = styled(CardContent)({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 150,
  width: 160,
  backgroundColor: "#b2dfdb",
  color: "white",
  borderRadius: "50%",
});

const StyledTypography = styled(Typography)({
  marginBottom: 15,
  color: "gray",
});

const StyledNumberTypography = styled(Typography)({
  fontSize: "2rem",
  fontWeight: "bold",
});

const WidgetCard = ({ totalCertifications, title, lineColor }) => {
  return (
    <StyledCard>
      <StyledCardContent>
        <StyledTypography align="center" variant="h6" component="div">
          {title}
        </StyledTypography>
        <StyledBox style={{ backgroundColor: lineColor }}>
          <StyledNumberTypography>{totalCertifications}</StyledNumberTypography>
        </StyledBox>
      </StyledCardContent>
    </StyledCard>
  );
};

export default WidgetCard;
